<template>
  <div>
    <div class="empty_wrap">
      <div class="img_box"></div>
      <div class="text_box">{{$t('no_default_list')}}</div>
    </div>
<!--    내역이 없어요..-->
  </div>
</template>

<script>
export default {
  name: "HistoryEmptyDefaultLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>